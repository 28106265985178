import { render, staticRenderFns } from "./Ratings.vue?vue&type=template&id=b194a3a8&scoped=true&"
import script from "./Ratings.vue?vue&type=script&lang=js&"
export * from "./Ratings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b194a3a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b194a3a8')) {
      api.createRecord('b194a3a8', component.options)
    } else {
      api.reload('b194a3a8', component.options)
    }
    module.hot.accept("./Ratings.vue?vue&type=template&id=b194a3a8&scoped=true&", function () {
      api.rerender('b194a3a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Ratings.vue"
export default component.exports