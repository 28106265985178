var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("appointment-history", {
    attrs: { title: "Feedback", "show-journal": false, ratings: true },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }