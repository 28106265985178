<template>
  <appointment-history title="Feedback" :show-journal="false" :ratings="true" />
</template>

<script>
import AppointmentHistory from '@/components/appointments/AppointmentHistory';

export default {
  components: {
    AppointmentHistory,
  },
};
</script>

<style lang="scss" scoped></style>
